import { isSameDay } from 'date-fns';
import { useCallback, useMemo, useState } from 'react';

import { Column } from '../Column';
import { DatePicker } from '../DatePicker';
import { Error } from '../Error';
import { ErrorWrapper } from '../ErrorWrapper';
import { HeadingText } from '../HeadingText';
import { Spinner } from '../Spinner';

import { ConfirmDateButton } from './ConfirmButton';

import { useAvailableDatesQuery } from '@/hooks/queries/useAvailableDatesQuery';
import { useChangeDateQueryParams } from '@/hooks/useChangeDateQueryParams';
import { useTrackingNumber } from '@/hooks/useTrackingNumber';
import { availableDatesToDatesArray } from '@/utils/availableDates';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { formatDate } from '@/utils/date';

const AvailableDatePicker = () => {
  const trackingNumber = useTrackingNumber();
  const { date, type } = useChangeDateQueryParams();
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();
  const {
    data: availableDatesResponse,
    isError,
    isLoading,
  } = useAvailableDatesQuery({ trackingNumber, type });

  const availableDates = useMemo(() => {
    let availableDates: Date[] = [];

    if (availableDatesResponse?.success)
      availableDates = [
        ...availableDatesToDatesArray(availableDatesResponse.dates),
      ];

    if (availableDates?.length && availableDates.length > 0) {
      if (date) {
        if (
          availableDates.some((availableDate) =>
            isSameDay(availableDate, new Date(date))
          )
        ) {
          setSelectedDate(new Date(date));
        }
      } else {
        setSelectedDate(availableDates[0]);
      }
    }

    return availableDates;
  }, [availableDatesResponse?.dates, availableDatesResponse?.success, date]);

  const handleDateChange = useCallback(
    (date: Date | null) => {
      if (date) setSelectedDate(new Date(date));
    },
    [setSelectedDate]
  );

  const errorMessage = useMemo(() => {
    if (availableDates.length === 0) {
      return `${capitalizeFirstLetter(
        type
      )} date cannot be changed - no dates available.`;
    }
  }, [availableDates.length, type]);

  return isLoading ? (
    <Spinner />
  ) : (
    <ErrorWrapper
      ErrorComponent={<Error text={errorMessage} />}
      SuccessComponent={
        <Column className='gap-1'>
          <HeadingText>Date</HeadingText>
          <Column className='gap-3'>
            <DatePicker
              currentValue={selectedDate && formatDate(new Date(selectedDate))}
              includeDates={availableDates}
              onChange={handleDateChange}
            />
            <div>
              <ConfirmDateButton date={selectedDate} />
            </div>
          </Column>
        </Column>
      }
      isError={isError || Boolean(errorMessage)}
    />
  );
};

export { AvailableDatePicker };
