import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Container } from '@/components/Container';
import { HeadingText } from '@/components/HeadingText';
import { Page } from '@/components/Page';
import Searchbar from '@/components/Searchbar';
import FAQ from '@/components/FAQ';

const HEADER = 'Track Your Shipment';
const CTA_SEARCH = 'Search';

const Home = () => {
  const navigate = useNavigate();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const { handleSubmit, register, watch } = useForm<{
    trackingNumber: string;
  }>();

  useEffect(() => {
    const sub = watch(({ trackingNumber }) => {
      if (trackingNumber && trackingNumber.length >= 8) {
        setIsButtonDisabled(false);
      } else {
        setIsButtonDisabled(true);
      }
    });
    return () => sub.unsubscribe();
  }, [watch]);

  const onSubmit = handleSubmit(({ trackingNumber }) => {
    const trimmedTrackingNumber = trackingNumber.trim();
    navigate(`/track/${trimmedTrackingNumber}`);
  });

  return (
    <Page className=' mt-5 flex  flex-col md:mt-20'>
      <Container className='w-full max-w-2xl'>
        <form
          className='mx-auto flex max-w-2xl flex-col items-start space-y-5'
          onSubmit={onSubmit}
        >
          <HeadingText className='text-2xl'>{HEADER}</HeadingText>
          <Searchbar
            isButtonDisabled={isButtonDisabled}
            register={register}
            cta={CTA_SEARCH}
          />
        </form>
        <FAQ />
        {/* {import.meta.env.DEV && <SinkPageLink />} */}
      </Container>
    </Page>
  );
};

export { Home };
