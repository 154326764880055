import { type Extendable } from '@/types/props';

type Props = Extendable;

const EmphasisText = ({ children, className }: Props) => (
  <p className={`font-content-bold text-5xl md:text-6xl ${className}`}>
    {children}
  </p>
);

export { EmphasisText };
export type { Props as EmphasisTextProps };
