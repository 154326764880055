import { Link as RouterLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='flex flex-1 flex-col items-center justify-center gap-4'>
      <p className='text-center font-headings-semibold text-3xl md:text-4xl'>
        Page not found
      </p>
      <RouterLink to='/'>
        <p className='text-center underline'>Back to homepage</p>
      </RouterLink>
    </div>
  );
};

export { NotFound };
