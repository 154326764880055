import { format, parse } from 'date-fns';

const FORMAT = 'yyyy-MM-dd';
const FORMAT_PRETTY = 'EEEE, MMMM do yyyy';
const FORMAT_WITH_TIME = 'd MMM yyyy - HH:mm';

const formatDate = (date: Date) => {
  return format(date, FORMAT);
};

const formatDatePretty = (date: Date) => {
  return format(date, FORMAT_PRETTY);
};

const formatDateWithTime = (date: Date) => {
  return format(new Date(date), FORMAT_WITH_TIME);
};

const parseDate = (date: string) => {
  return parse(date, FORMAT, new Date());
};

export { formatDate, parseDate, formatDatePretty, formatDateWithTime };
