type ErrorProps = {
  text?: string;
};

const Error = ({ text }: ErrorProps) => {
  return (
    <p className='font-content text-red-400 md:text-lg'>
      {text ? (
        text
      ) : (
        <>
          Something went wrong, please try again later. If the issue persists,
          email us at{' '}
          <a className='underline' href='mailto:contact@broad.work'>
            hello@broad.work
          </a>
        </>
      )}
    </p>
  );
};

export { Error };
export type { ErrorProps };
