import { QuestionFAQProps } from '@/types/props';
import React from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';

const Question: React.FC<QuestionFAQProps> = (props) => {
  const { question, onClick, isActive } = props;
  return (
    <div
      onClick={onClick}
      className={
        'text-md flex w-full cursor-pointer items-center justify-between gap-3 border-b p-5 font-medium text-gray-600 hover:bg-gray-100 md:text-lg  '
      }
    >
      {question}
      <div
        className={`transition-transform duration-300 ease-in-out ${
          isActive ? 'rotate-180' : 'rotate-0'
        }`}
      >
        <MdKeyboardArrowDown />
      </div>
    </div>
  );
};

export default Question;
