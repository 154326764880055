import { useEffect, useMemo } from 'react';

import { Button } from '@/components/Button';
import { Container } from '@/components/Container';
import { EmphasisText } from '@/components/EmphasisText';
import { Error } from '@/components/Error';
import { HeadingText } from '@/components/HeadingText';
import { Page } from '@/components/Page';
import { Spinner } from '@/components/Spinner';
import { Text } from '@/components/Text';
import { useTransportQuery } from '@/hooks/queries/useTransportQuery';
import { useToken } from '@/hooks/useToken';
import { useTrackingNumber } from '@/hooks/useTrackingNumber';
import { getApiUrl } from '@/utils/urls';

const API_URL = getApiUrl();

const Label = () => {
  const trackingNumber = useTrackingNumber();
  const { token } = useToken();
  const { data, error, isError, isLoading } = useTransportQuery({
    trackingNumber,
  });
  const url = useMemo(
    () =>
      `${API_URL}/api/v1/transports/labels/${trackingNumber}?token=${token}`,
    [token, trackingNumber]
  );
  // If the transport is delivered, the label cannot be accessed anymore
  const isDelivered = useMemo(() => {
    if (data?.success) {
      return data.transport.events.some(
        (transportEvent) => transportEvent.event.name === 'Delivered'
      );
    }
  }, [data?.success, data?.transport.events]);

  useEffect(() => {
    if (!isLoading && !isError && !isDelivered) {
      location.href = url;
    }
  }, [isDelivered, isError, isLoading, url]);

  return (
    <Page>
      <Container>
        <div className='flex flex-col gap-4'>
          <div>
            <HeadingText>Tracking number</HeadingText>
            <EmphasisText>{trackingNumber}</EmphasisText>
          </div>
          {isError && <Error text={error.response?.data.error.message} />}
          {isLoading && <Spinner className='h-12 w-12' />}
          {!isLoading && (
            <div>
              {isDelivered ? (
                <Text>Package {trackingNumber} has been delivered already</Text>
              ) : (
                <a href={url} rel='noopener noreferrer' target='_blank'>
                  <Button tabIndex={-1}>Print shipping label</Button>
                </a>
              )}
            </div>
          )}
        </div>
      </Container>
    </Page>
  );
};

export { Label };
