import { type ReactNode } from 'react';

import { Error } from '@/components/Error';

type ErrorWrapperProps = {
  ErrorComponent?: ReactNode;
  SuccessComponent: ReactNode;
  isError: boolean;
};

const ErrorWrapper = ({
  ErrorComponent,
  isError,
  SuccessComponent,
}: ErrorWrapperProps) => {
  if (!isError) return <>{SuccessComponent}</>;

  return <>{ErrorComponent}</> ?? <Error />;
};

export { ErrorWrapper };
export type { ErrorWrapperProps };
