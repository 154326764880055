import ReactDatePicker from 'react-datepicker';

import { Error } from '@/components/Error';

type DatePickerProps = {
  currentValue?: string;
  includeDates: Date[];
  onChange: (date: Date | null) => void;
};

const DatePicker = ({
  currentValue,
  includeDates,
  onChange,
}: DatePickerProps) => {
  if (includeDates.length === 0)
    return (
      <Error text='Date picker error: There are no dates to choose from.' />
    );

  return (
    <ReactDatePicker
      className='w-72 rounded-md border-2 border-black p-2 focus:outline-accent focus-visible:rounded-md'
      includeDates={includeDates}
      onChange={onChange}
      showPopperArrow={false}
      value={currentValue}
    />
  );
};

export { DatePicker };
