import { SearchbarProps } from '@/types/props';
import React from 'react';
import { Button } from './Button';
import { MdOutlineSearch } from 'react-icons/md';

const Searchbar: React.FC<SearchbarProps> = (props) => {
  const { isButtonDisabled, register, cta } = props;

  return (
    <div className='relative flex w-full'>
      <input
        type='text'
        className='block w-full rounded-lg border border-gray-300 bg-gray-50 p-3 ps-2 text-sm text-gray-900 focus:border-banner '
        placeholder='Enter tracking number...'
        {...register('trackingNumber', { required: true })}
      />

      <Button
        type='submit'
        disabled={isButtonDisabled}
        className='text-md ml-2 flex items-center rounded-lg  border-banner bg-banner  px-3 font-medium text-white '
      >
        <MdOutlineSearch size={'1.5em'} />
        {cta}
      </Button>
    </div>
  );
};

export default Searchbar;
