import { StrictMode } from 'react';
import ReactDOM from 'react-dom';

// Styles
import '@/styles/global.css';
import '@/styles/fonts.css';
import '@/styles/react-datepicker.css';
import 'react-datepicker/dist/react-datepicker.css';

// Utilities
import '@/utils/setupAxios';

import App from '@/core/App';
import { initSentry, reportErrorToSentry } from '@/utils/setupSentry';

try {
  initSentry();

  ReactDOM.render(
    <StrictMode>
      <App />
    </StrictMode>,
    document.querySelector('#root')
  );
} catch (error) {
  reportErrorToSentry(error);
  // Show error page istead of alert
  // eslint-disable-next-line no-alert
  alert(
    'Something went wrong. Try again later or contact us at contact@broad.work if the issue persists.'
  );
}
