import { AvailableDatePicker } from '@/components/AvailableDatePicker';
import { Column } from '@/components/Column';
import { Container } from '@/components/Container';
import { EmphasisText } from '@/components/EmphasisText';
import { HeadingText } from '@/components/HeadingText';
import { Page } from '@/components/Page';
import { useChangeDateQueryParams } from '@/hooks/useChangeDateQueryParams';
import { useTrackingNumber } from '@/hooks/useTrackingNumber';

const ChangeDate = () => {
  const trackingNumber = useTrackingNumber();
  const { type } = useChangeDateQueryParams();

  return (
    <Page>
      <Container>
        <Column className='gap-4'>
          <div>
            <HeadingText>Tracking number</HeadingText>
            <EmphasisText className='text-5xl'>{trackingNumber}</EmphasisText>
          </div>
          <p>Use the form below to change the {type} date of this package.</p>
          <AvailableDatePicker />
        </Column>
      </Container>
    </Page>
  );
};

export { ChangeDate };
