import axios, { type AxiosError } from 'axios';
import transformToCamelCase from 'camelcase-keys';
import { useQuery } from 'react-query';

import { type TTransport } from '@/types/models';
import { type TBaseRequestPayload } from '@/types/request';
import { type TBaseResponse, type TErrorResponse } from '@/types/response';

type TGetTransportRequestPayload = TBaseRequestPayload & {
  trackingNumber: string;
};
type TGetTransportResponse = TBaseResponse & {
  transport: TTransport;
};

const getTransport = async ({
  signal,
  trackingNumber,
}: TGetTransportRequestPayload) => {
  const res = await axios.get(`/api/v1/track/${trackingNumber}`, { signal });

  return transformToCamelCase(res.data, {
    deep: true,
  });
};

const useTransportQuery = ({ trackingNumber }: TGetTransportRequestPayload) => {
  return useQuery<TGetTransportResponse, AxiosError<TErrorResponse>>(
    ['transport', trackingNumber],
    ({ signal }) =>
      getTransport({
        signal,
        trackingNumber,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      retry: 0,
    }
  );
};

export { useTransportQuery };
