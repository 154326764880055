import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { Button } from '../Button';
import { HeadingText } from '../HeadingText';
import { Modal } from '../Modal';

import { useUpdateTransportDateMutation } from '@/hooks/mutations/useTransportDateMutation';
import { useChangeDateQueryParams } from '@/hooks/useChangeDateQueryParams';
import { useTrackingNumber } from '@/hooks/useTrackingNumber';
import { capitalizeFirstLetter } from '@/utils/capitalizeFirstLetter';
import { formatDate, formatDatePretty } from '@/utils/date';

type ConfirmDateButtonProps = {
  date?: Date;
};

const ConfirmDateButton = ({ date }: ConfirmDateButtonProps) => {
  const trackingNumber = useTrackingNumber();
  const { token, type } = useChangeDateQueryParams();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const {
    isError: isUpdateTransportDateError,
    isLoading: isUpdateTransportDateLoading,
    isSuccess: isUpdateTransportDateSuccess,
    mutate: updateTransportDate,
  } = useUpdateTransportDateMutation();

  const handleConfirm = useCallback(() => {
    if (!date) return toast(`Please select a ${type} date`);

    updateTransportDate(
      {
        date: formatDate(date),
        token,
        trackingNumber,
      },
      {
        onError(error) {
          toast(
            error.response?.data?.error?.message
              ? error.response?.data?.error?.message
              : `${capitalizeFirstLetter(type)} date could not be changed.`,
            {
              className: 'bg-red-400 text-center text-white',
              duration: 10_000,
            }
          );
        },
        onSuccess: () => {
          openModal();
        },
      }
    );
  }, [date, openModal, token, trackingNumber, type, updateTransportDate]);

  return (
    <>
      {date && (
        <Modal
          className='max-w-sm bg-green-400'
          isOpen={isModalOpen}
          onClose={closeModal}
        >
          <div>
            <HeadingText className='font-bold'>Confirmation</HeadingText>
            <p className='my-4'>
              {`${capitalizeFirstLetter(
                type
              )} date has been successfully changed to ${formatDatePretty(
                date
              )}.`}
            </p>
            <div className='flex justify-end'>
              <Button onClick={() => closeModal()}>OK!</Button>
            </div>
          </div>
        </Modal>
      )}
      <div>
        <Button
          disabled={!date}
          isError={isUpdateTransportDateError}
          isLoading={isUpdateTransportDateLoading}
          isSuccess={isUpdateTransportDateSuccess}
          onClick={handleConfirm}
        >
          Confirm
        </Button>
      </div>
    </>
  );
};

export { ConfirmDateButton };
