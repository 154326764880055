import { useState } from 'react';

import { Row } from './Row';
import { Spinner } from './Spinner';

type TImageProps = {
  alt: string;
  src?: string;
  title?: string;
};

const Loading = () => {
  return (
    <div className='absolute'>
      <Spinner />
    </div>
  );
};

const Image = ({ alt, src, title }: TImageProps) => {
  const [isLoading, setIsLoading] = useState(Boolean(src));

  return (
    <>
      {title && <p>{title}</p>}
      <Row className='w-full justify-center' isCentered>
        {isLoading && <Loading />}
        <img
          alt={alt}
          className={isLoading ? 'invisible' : 'visible'}
          onLoad={() => setIsLoading(false)}
          src={src}
        />
      </Row>
    </>
  );
};

export { Image };
export type { TImageProps };
