import React, { useState } from 'react';
import Question from './Question';
import Answer from './Answer';
import { SectionFAQ } from '@/types/props';

const Section: React.FC<SectionFAQ> = (props) => {
  const { question, answer } = props;
  const [isActive, setIsActive] = useState<boolean>(false);

  const toggleActive = () => setIsActive(!isActive);

  return (
    <>
      <Question
        question={question}
        onClick={toggleActive}
        isActive={isActive}
      />
      <Answer isActive={isActive} answer={answer} />
    </>
  );
};

export default Section;
