const countryCodeToCountryName = (code: string) => {
  switch (code) {
    case 'DK':
      return 'Denmark';
    case 'SE':
      return 'Sweden';
    case 'NO':
      return 'Norway';
    default:
      return code;
  }
};

export { countryCodeToCountryName };
