import { HeadingText } from './HeadingText';
import { Text } from './Text';

const Stat = ({ title, value }: { title: string; value: string }) => {
  return (
    <div className='w-full divide-y divide-gray-200 overflow-hidden rounded-lg border border-gray-200 lg:max-w-xl xl:max-w-2xl '>
      <div className='space-y-1 px-4 py-2'>
        <Text>{title}</Text>
        <HeadingText className='text-md font-bold md:text-xl'>
          {value}
        </HeadingText>
      </div>
    </div>
  );
};

export { Stat };
