import { type Extendable } from '@/types/props';

type Props = Extendable;

const Page = ({ children, className }: Props) => {
  return <main className={`flex-1 py-2 ${className}`}>{children}</main>;
};

export { Page };
export type { Props as PageProps };
