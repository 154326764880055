import { countryCodeToCountryName } from '../countryCodeToCountryName';

const formatAddress = (
  city?: string,
  zip?: string,
  country?: string
): string => {
  const cityAndZip: string[] = [];
  const parts: string[] = [];

  if (zip && !city && !country) {
    return `ZIP Code: ${zip}`;
  }

  if (city && !zip && !country) {
    return `City: ${city}`;
  }

  if (country && !city && !zip) {
    return `Country: ${countryCodeToCountryName(country)}`;
  }

  if (city || zip) {
    if (city) {
      cityAndZip.push(city);
    }

    if (zip) {
      cityAndZip.push(zip);
    }

    parts.push(cityAndZip.join(', '));
  }

  if (country) {
    parts.push(countryCodeToCountryName(country));
  }

  return parts.join(' ');
};

export { formatAddress };
