import { type ReactNode, useEffect, useState } from 'react';
import { MdCheck, MdErrorOutline } from 'react-icons/md';

import { Spinner } from './Spinner';

import { type Extendable } from '@/types/props';

type Props = Extendable &
  React.ButtonHTMLAttributes<HTMLButtonElement> & {
    isError?: boolean;
    isLoading?: boolean;
    isSuccess?: boolean;
  };

const StateWrapper = ({
  children,
  isVisible,
}: {
  children: ReactNode;
  isVisible?: boolean;
}) => (
  <div
    aria-hidden={!isVisible}
    className={`absolute left-0 top-0 flex h-full w-full items-center justify-center ${
      isVisible ? 'block' : 'invisible'
    }`}
  >
    {children}
  </div>
);

const LoadingState = ({ isLoading }: { isLoading: Props['isLoading'] }) => (
  <StateWrapper isVisible={isLoading}>
    <Spinner className='h-full w-full p-1' />
  </StateWrapper>
);

const SuccessState = ({ isSuccess }: { isSuccess?: Props['isSuccess'] }) => (
  <StateWrapper isVisible={isSuccess}>
    <MdCheck className='h-full w-full p-2' />
  </StateWrapper>
);

const ErrorState = ({ isError }: { isError?: Props['isError'] }) => (
  <StateWrapper isVisible={isError}>
    <MdErrorOutline className='h-full w-full p-2' />
  </StateWrapper>
);

const Button = ({
  children,
  className,
  disabled,
  isError,
  isLoading,
  isSuccess,
  ...rest
}: Props) => {
  const [isDisabled, setIsDisabled] = useState(disabled);

  useEffect(() => {
    if (isError || isSuccess || isLoading || disabled) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [disabled, isError, isLoading, isSuccess]);

  return (
    <button
      className={`relative rounded-md  px-6  text-white
      ${isSuccess && 'bg-green-500'} ${isError && 'bg-red-500'} 
      ${isDisabled ? 'bg-gray-300 ' : ' hover:shadow-lg '} ${className}`}
      disabled={isDisabled}
      type='button'
      {...rest}
    >
      <LoadingState isLoading={isLoading} />
      <SuccessState isSuccess={isSuccess} />
      <ErrorState isError={isError} />
      <div
        aria-hidden={isLoading ?? isSuccess ?? isError}
        className={`flex  items-center justify-center  space-x-2 ${
          isLoading || isSuccess || isError ? 'invisible' : 'block'
        }`}
      >
        {children}
      </div>
    </button>
  );
};

export { Button };
export type { Props as ButtonProps };
