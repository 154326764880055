import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

/**
 * Custom hook that redirects the user to /404 if the trackingNumber parameter is falsy
 */
const useTrackingNumber = () => {
  const navigate = useNavigate();
  const { trackingNumber } = useParams();

  useEffect(() => {
    if (!trackingNumber) {
      navigate('/404');
    }
  }, [navigate, trackingNumber]);

  return trackingNumber ?? '';
};

export { useTrackingNumber };
