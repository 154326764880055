import { type TAPIAvailableDates, type TAvailableDates } from '@/types/models';
import { parse } from 'date-fns';
import { parseDate } from '../date';

const availableDatesToDatesArray = (availableDatesObj: TAvailableDates) => {
  const availableDates: Date[] = [];

  for (const availableDate in availableDatesObj) {
    if (
      Object.prototype.hasOwnProperty.call(availableDatesObj, availableDate)
    ) {
      const date = parseDate(availableDate);
      availableDates.push(date);
    }
  }

  return availableDates;
};

const transformAvailableDatesAPIResponse = (
  apiAvailableDates: TAPIAvailableDates
): TAvailableDates => {
  const availableDates: TAvailableDates = {};

  for (const date in apiAvailableDates) {
    if (Object.prototype.hasOwnProperty.call(apiAvailableDates, date)) {
      const { hour_end, hour_start, name } = apiAvailableDates[date];
      availableDates[date] = {
        hourEnd: hour_end,
        hourStart: hour_start,
        name,
      };
    }
  }

  return availableDates;
};

export { availableDatesToDatesArray, transformAvailableDatesAPIResponse };
