import { useSearchParams } from 'react-router-dom';

const useToken = () => {
  const [searchParams] = useSearchParams();
  const { s } = Object.fromEntries(searchParams);

  return { token: s };
};

export { useToken };
