const eventIcons: Record<string, string> = {
  'ac0b2b3a-fea2-4c78-89c5-7b346edf766e': 'pick_up',
  '56d0438f-aa88-45ca-b8b4-fdda83b9ef59': 'delivered',
  '8d56d5aa-a317-4c54-8d6c-bb329fcb3fed': 'departure_depot',
  '44eecddd-44b2-4a6d-a899-57471bd556f4': 'pickup_or_delivery_postpone',
  '6764fa64-5c90-41be-933e-f7d763fc0c65': 'pickup_or_delivery_postpone',
  '1b0c2da9-093d-44c8-8ac9-1b631fe1dc71': 'arrival_at_depot',
};

const faqs = [
  {
    question: 'What is the return policy?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    isActive: false,
  },
  {
    question: 'How do I track my order?',
    answer:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    isActive: false,
  },
];

export { faqs, eventIcons };
