import * as Sentry from '@sentry/react';
import axios from 'axios';

const initSentry = () => {
  if (process.env.NODE_ENV !== 'development') {
    Sentry.init({
      // General
      dsn: 'https://2b36ab567b8a30ccc2767b04e6ba2fb9@o4504616065892352.ingest.sentry.io/4505685210759168',

      // Integrations
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: ['localhost', 'https:yourserver.io/api/'],
        }),
        Sentry.replayIntegration({
          maskAllText: false,
        }),
      ],

      // Sample rates
      replaysOnErrorSampleRate: 0.33,
      replaysSessionSampleRate: 0.33,
      tracesSampleRate: 1,
    });
  }
};

const report = (error: unknown) => {
  Sentry.captureException(error);
};

const hasErrorProperty = (error: unknown): error is { error: unknown } => {
  return (
    error !== null &&
    typeof error === 'object' &&
    'error' in error &&
    typeof (error as { error: unknown }).error === 'object'
  );
};

const hasMessageProperty = (error: unknown): error is { message: string } => {
  return (
    error !== null &&
    typeof error === 'object' &&
    'message' in error &&
    typeof (error as { message: string }).message === 'string'
  );
};

const reportErrorToSentry = (error: unknown) => {
  if (!axios.isAxiosError(error)) return report(error);

  const response = error.response;

  if (!hasErrorProperty(response?.data)) return report(response?.data);

  if (!hasMessageProperty(response?.data.error))
    return report(response?.data.error);

  return report(new Error(response?.data.error.message));
};

export { initSentry, reportErrorToSentry };
