import { Toaster } from 'react-hot-toast';
import { QueryClient, QueryClientProvider } from 'react-query';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Footer } from '@/components/Footer';
import { Header } from '@/components/Header';
import { ChangeDate } from '@/pages/ChangeDate';
import { Home } from '@/pages/Home';
import { Label } from '@/pages/Label';
import { NotFound } from '@/pages/NotFound';
import { Sink } from '@/pages/Sink';
import { Track } from '@/pages/Track';
import { reportErrorToSentry } from '@/utils/setupSentry';

const App = () => {
  return (
    <div className='flex min-h-screen flex-col justify-between font-content selection:bg-black selection:text-white'>
      <Header />
      <Routes>
        <Route element={<Home />} path='' />
        {import.meta.env.DEV && <Route element={<Sink />} path='sink' />}
        <Route path='track'>
          <Route element={<Track />} path=':trackingNumber' />
          <Route element={<ChangeDate />} path=':trackingNumber/change-date' />
          <Route element={<Label />} path=':trackingNumber/label' />
        </Route>
        <Route element={<NotFound />} path='*' />
      </Routes>
      <Footer />
      <Toaster position='bottom-center' />
    </div>
  );
};

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: reportErrorToSentry,
    },
    queries: {
      onError: reportErrorToSentry,
    },
  },
});

const Providers = () => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default Providers;
