import { type Extendable } from '@/types/props';

type RowProps = Extendable & {
  isCentered?: boolean;
};

const Row = ({ children, className, isCentered }: RowProps) => {
  return (
    <div
      className={`flex flex-row gap-2 ${
        isCentered && 'items-center'
      } ${className}`}
    >
      {children}
    </div>
  );
};

export { Row };
export type { RowProps };
