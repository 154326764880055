import { type Extendable } from '@/types/props';

type ContainerProps = Extendable;

const Container = ({ children, className }: ContainerProps) => {
  return (
    <div className={`mx-auto max-w-4xl px-2 sm:px-6 md:px-8  ${className}`}>
      {children}
    </div>
  );
};

export { Container };
export type { ContainerProps };
