import { type ReactNode, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { twMerge } from 'tailwind-merge';

type Props = {
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  onClose: () => void;
};

const Modal = ({ children, className, isOpen, onClose }: Props) => {
  const portal = useMemo(
    () =>
      createPortal(
        <div
          className='absolute inset-0 z-10 flex items-center justify-center bg-black/75 p-2'
          onClick={() => onClose()}
        >
          <div
            className={twMerge('rounded-md bg-white p-4', className)}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {children}
          </div>
        </div>,
        document.body
      ),
    [children, className, onClose]
  );

  if (!isOpen) return null;

  return portal;
};

export type { Props as ModalProps };
export { Modal };
