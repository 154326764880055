import { type Extendable } from '@/types/props';

type Props = Extendable;

const HeadingText = ({ children, className }: Props) => (
  <p className={`font-headings text-xl md:text-2xl ${className}`}>{children}</p>
);

export { HeadingText };
export type { Props as HeadingTextProps };
