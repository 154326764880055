import axios, { type AxiosError } from 'axios';
import { useMutation } from 'react-query';

import { type TBaseRequestPayload } from '@/types/request';
import { type TBaseResponse, type TErrorResponse } from '@/types/response';

type TUpdateTransportDateResponse = TBaseResponse;

type TUpdateTransportRequestPayload = TBaseRequestPayload & {
  date?: string;
  token?: string;
  trackingNumber?: string;
};

const updateTransportDate = async ({
  date,
  token,
  trackingNumber,
}: TUpdateTransportRequestPayload): Promise<TUpdateTransportDateResponse> => {
  const response = await axios.patch<TUpdateTransportDateResponse>(
    `/api/v1/transports/${trackingNumber}/dates`,
    {
      date,
      token,
    }
  );

  return response.data;
};

const useUpdateTransportDateMutation = () => {
  return useMutation<
    TUpdateTransportDateResponse,
    AxiosError<TErrorResponse>,
    TUpdateTransportRequestPayload
  >((payload) => updateTransportDate(payload));
};

export { useUpdateTransportDateMutation, updateTransportDate };
