import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { type TChangeDateTypes } from '@/types/models';

const useChangeDateQueryParams = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { d, s, t } = Object.fromEntries(searchParams);

  useEffect(() => {
    if (s === undefined || t === undefined) navigate('/404');
  }, [navigate, s, t]);

  return {
    date: d as string | null,
    token: s ?? ('' as string | undefined),
    type: (t ?? 'delivery') as TChangeDateTypes,
  };
};

export { useChangeDateQueryParams };
