import axios, { type AxiosError } from 'axios';
import { useQuery } from 'react-query';

import {
  type TAPIAvailableDates,
  type TAvailableDates,
  type TChangeDateTypes,
} from '@/types/models';
import { type TBaseRequestPayload } from '@/types/request';
import { type TBaseResponse, type TErrorResponse } from '@/types/response';
import { transformAvailableDatesAPIResponse } from '@/utils/availableDates';

type TGetAvailableDatesRequestPayload = TBaseRequestPayload & {
  trackingNumber?: string;
  type?: TChangeDateTypes;
};

type TGetAvailableDatesAPIResponse = TBaseResponse & {
  dates: TAPIAvailableDates;
};

type TGetAvailableDatesResponse = TBaseResponse & {
  dates: TAvailableDates;
};

const getAvailableDates = async ({
  signal,
  trackingNumber,
  type,
}: TGetAvailableDatesRequestPayload) => {
  const res = await axios.get<TGetAvailableDatesAPIResponse>(
    `/api/v1/transports/${trackingNumber}/dates?type=${type}`,
    { signal }
  );

  const { dates, success } = res.data;

  return { dates: transformAvailableDatesAPIResponse(dates), success };
};

const useAvailableDatesQuery = ({
  trackingNumber,
  type,
}: TGetAvailableDatesRequestPayload) => {
  return useQuery<TGetAvailableDatesResponse, AxiosError<TErrorResponse>>(
    ['availableDates', trackingNumber, type],
    ({ signal }) =>
      getAvailableDates({
        signal,
        trackingNumber,
        type,
      }),
    {
      keepPreviousData: false,
      refetchOnWindowFocus: false,
    }
  );
};

export { useAvailableDatesQuery };
export type { TGetAvailableDatesAPIResponse };
