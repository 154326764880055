import { faqs } from '@/constants';
import { HeadingText } from '../HeadingText';
import Section from './Section';

const HEADER = 'Frequently Asked Questions';

export default function FAQ() {
  return (
    <div className='mx-auto mt-10 w-full max-w-2xl rounded-lg border-white bg-gray-50  '>
      <HeadingText className='mb-6 text-lg md:text-xl'>{HEADER}</HeadingText>

      <div className='rounded-t-lg border border-b-0'>
        {faqs.map((faq, idx) => (
          <Section
            key={`${faq.question}_${idx}`}
            question={faq.question}
            answer={faq.answer}
          />
        ))}
      </div>
    </div>
  );
}
