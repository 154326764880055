import { EventCard } from '@/components/EventCard';
import { type TTransportEvent } from '@/types/models';

type EventsProps = {
  events: TTransportEvent[];
};

const Events = ({ events }: EventsProps) => {
  return (
    <section className='flex  w-full  flex-col space-y-6 rounded-lg border  p-2 px-5 shadow-sm '>
      <h3 className='text-xl font-semibold text-gray-900 '>Order history</h3>

      <ol className='relative ms-3 border-s '>
        {events.map((transportEvent) => (
          <EventCard
            key={JSON.stringify(transportEvent)}
            transportEvent={transportEvent}
          />
        ))}
      </ol>
    </section>
  );
};

export { Events };
