import { AnswerFAQProps } from '@/types/props';
import React from 'react';
import { HeadingText } from '../HeadingText';

const Answer: React.FC<AnswerFAQProps> = (props) => {
  const { isActive, answer } = props;
  return (
    <div className={`border-b  p-5 ${!isActive && 'hidden'}`}>
      <HeadingText className='mb-2 flex text-xs text-gray-500 md:text-sm '>
        {answer}
      </HeadingText>
    </div>
  );
};

export default Answer;
